

// Root layout
html, body {
  font-family: Pretendard;
  font-weight: normal;
  min-width: 100%;
  min-height: 100%;
  text-align: center;
}

#root {
  display: inline-block;
  height: 100%;
  width: 100%;
}